<template>
    <div>
        <TitleAdmin msg="Roles" routerAdmin="Admin" />
        <Role />
    </div>
</template>

<script>
import TitleAdmin from '@/views/constants/titleAdmin.vue'
import Role from '@/components/admin/roles/index.vue'
export default {
    components: {
        TitleAdmin,
        Role
    }
}
</script>