<template>
<div>
    <p>Create</p>
    <div class="row">
        <div class="col">
            <form>
                <div class="form-group row">
                    <label for="inputEmail3" class="col-sm-2 col-form-label">Name</label>
                    <div class="col-sm-10">
                        <input type="name" class="form-control" v-model="name" />
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary" @click="newRole">Guardar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <p>Roles</p>
    <div class="row">
        <div class="col">
            <div v-for="role in roles" :key="role.id">
                <ul>
                    <li>{{ role.id }}</li>
                    <li>{{ role.name }}</li>
                    <li>{{ role.description }}</li>
                </ul>
            </div>
        </div>
    </div>
    <p>Role zzzz</p>
    <div class="row">
        <div class="col">
            <div v-for="role in roles" :key="role.id">
                <ul>
                    <li>{{ role.id }}</li>
                    <li>{{ role.name }}</li>
                    <li>{{ role.description }}</li>
                </ul>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-4">
            <div class="list-group" id="list-tab" role="tablist">
                <div v-for="role in roles2" :key="role.id">
                    <a class="list-group-item list-group-item-action active" 
                        :id="role.id" data-toggle="list" :href="'#' + role.id" role="tab" :aria-controls="role.id">{{ role.name }}</a>
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="tab-content" id="nav-tabContent">
                <div v-for="permission in permissions2" :key="permission.id">
                    <div v-if="permission.pivot">
                        <div v-for="name in permission" :key="name.id">
                            aaa {{ name.name }} bbb
                        </div>
                        <div class="tab-pane fade show active" :id="permission.pivot.role_id" role="tabpanel" 
                            :aria-labelledby="permission.pivot.role_id">dddd</div>
                    </div>
                    <div v-else>
                        nadaa
                        11
                        {{ permission }}
                        22
                        {{ permission.name }}
                        33
                        {{ permission.pivot }}
                    </div>

                </div>
            </div>
        </div>
    </div>
<p>------</p>
    <div class="row">
  <div class="col-4">
    <div class="list-group" id="list-tab" role="tablist">
      <a class="list-group-item list-group-item-action active" id="list-home-list" data-toggle="list" href="#list-home" role="tab" aria-controls="home">Home</a>
      <a class="list-group-item list-group-item-action" id="list-profile-list" data-toggle="list" href="#list-profile" role="tab" aria-controls="profile">Profile</a>
      <a class="list-group-item list-group-item-action" id="list-messages-list" data-toggle="list" href="#list-messages" role="tab" aria-controls="messages">Messages</a>
      <a class="list-group-item list-group-item-action" id="list-settings-list" data-toggle="list" href="#list-settings" role="tab" aria-controls="settings">Settings</a>
    </div>
  </div>
  <div class="col-8">
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="list-home" role="tabpanel" aria-labelledby="list-home-list">...</div>
      <div class="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">...</div>
      <div class="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">...</div>
      <div class="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">...</div>
    </div>
  </div>
</div>
    
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'cRoles',
    data() {
        return {
            roles: '',
            name: '',
            roles2: [],
            permissions2: [],
        }
    },
    created() {
        this.getRoles();
        this.getRoles2();
    },
    methods: {
        getRoles() {
            const url = this.appDomainApi1 + "super-admin/roles/index";
            (url);
            const letf = this;
            axios.get(url, )
                .then(res => {
                    letf.roles = res.data
                })
                .catch(err => {
                    console.error(err);
                })
        },
        getRoles2() {
            const url = this.appDomainApi1 + "super-admin/roles/permissions";
            const letf = this;
            axios.get(url, )
                .then(res => {
                    letf.roles2 = res.data
                    //letf.permissions2 = res.data.permissions
                    res.data.forEach(element => {
                        letf.permissions2.push(element.permissions);
                    });
                })
                .catch(err => {
                    console.error(err);
                })
        },
        newRole(e) {
            e.preventDefault();

            const data = {
                name: this.name,
            };

            const url = this.appDomainApi1 + "super-admin/roles/store";
            const letf = this;
            axios.post(url, data)
                .then(response => {
                    letf.getRoles();
                    console.log(response);
                })
                .catch(response => {
                    console.error(response)
                });
        }
    },
}
</script>
